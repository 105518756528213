/* lista tipo JSON, 
    en esta lista se encuentran los datos de los profesores  */
export const profesores_data = [
    {
        ID: 1,
        Foto: require("../images/Equipo_de_trabajo/Elsa.jpg"),
        Nombre: "Dra. Elsa Rubio Espino",
        Nivel: "SNII 1 - Jefa de laboratorio",
        Correo: "erubio@cic.ipn.mx",
        Link: "",
        Bio: "Dra. en Sistemas automáticos, M. en C. en Control Automático, Ing. Procesos Automáticos",
        Unidades: "",
        Investigacion: "",
    },
    {
        ID: 2,
        Foto: require("../images/Equipo_de_trabajo/Juan_Sossa.jpg"),/*poner foto en la ruta, y cambiar el nombre alcorespondiente */
        Nombre: "Dr. Juan Humberto Sossa Azuela",
        Nivel: "SNII 3 (Emérito)",
        Correo: "humbertosossa@gmail.com",
        Link: "http://sites.google.com/site/cicvision/",/*poner link directo de la pagina personal entre comillas */
        Bio: "Dr. en Informática, M. en C. Ingeniería Eléctrica. Ing. Comunicaciones y Electrónica",
        Unidades: "",
        Investigacion: "Inteligencia Artificial, Machine Learning, Robótica",
    },
    {
        ID: 3,
        Foto: require("../images/Equipo_de_trabajo/Erik.jpg"),
        Nombre: "Dr. Erik Zamora",
        Nivel: "SNII 1",
        Correo: "ezamorag@ipn.mx",
        Link: "https://www.ezamorag.com/",
        Bio: "Dr. en Control Automático",
        Unidades: "",
        Investigacion: "Machine Learning, Deep Learning",
    },
    {
        ID: 4,
        Foto: require("../images/Equipo_de_trabajo/Yalja.jpg"),
        Nombre: "Dr. Jesús Yaljá Montiel Pérez",
        Nivel: "",
        Correo: "yalja@ipn.mx",
        Link: "https://proyectosrym.cic.ipn.mx/yalja/",
        Bio: "Dr. en Comunicaciones y Electrónica, M. en C. en Ingeniería electrónica, Ing. Físico",
        Unidades: "Modelado y control de robots, Habilidades Industriales, Análisis numérico, Transformadas matemáticas para imágenes, Mat. avanzadas, predicción y caos, Percepción visual digital, CITEDI",
        Investigacion: "Computación cuántica aplicada, Ciudades inteligentes, Internet del cuerpo, IoB, sensores, Internet de las cosas, IoT, sensores, Robots de servicio, Robots médicos, Navegación, Sensores y visión mejorada ",
    },
    {
        ID: 5,
        Foto: require("../images/Equipo_de_trabajo/Ponciano.jpg"),
        Nombre: "Dr. P Jorge Escamilla Ambrosio",
        Nivel: "SNII 2",
        Correo: "pescamillaa@ipn.mx",
        Link: "https://www.cic.ipn.mx/~pescamilla/index.html",
        Bio: "Dr. en Controles Automáticos en Ingeniería de Sistemas, M. en Ingeniería Eléctrica , Ing. Mecánico Electricista",
        Unidades: "",
        Investigacion: "Ciberseguridad, Internet de las cosas, Redes de sensores inalámbricos, Fusión/integración de sensores/información, Sistemas de control difuso, Redes neuronales artificiales, Sistemas neuro-difusos y Control inteligente/adaptativo",
    },
]