import { useState } from "react";
import { NavLink } from "../templates/NavLink";
import logoIPN from "../images/IPN-logo.png";
import logoCIC from "../images/cic-logo.png";
import logoLab from "../images/logolabClaro.png";

export const ListLinksMenu = (props) => {
  const styles = props.styles
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownEventos, setDropdownEventos] = useState(false);
  const [dropdownInvestigacion, setDropDownInvestigacion] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleDropdownEventos = () => {
    setDropdownEventos(!dropdownEventos);
  };

  const toggleDropdownInvestigacion = () => {
    setDropDownInvestigacion(!dropdownInvestigacion);
  };

  const logosPrincipal = () => {
    return (
      <div className={styles.logos}>
        <a href="https://www.ipn.mx">
          <img src={logoIPN} alt="logo IPN"  height="50px"/>
        </a> &nbsp;
        <a href="https://www.cic.ipn.mx/">
          <img src={logoCIC} alt="logo CIC"  height="50px"/>
        </a>&nbsp;
        <a href="#">
          <img src={logoLab} alt="logo Laboratorio"  height="50px"/>
        </a>
      </div>
    );
  };

  const logosSubprincipal = () => {
    return (
      <p className={styles.logoCenter}>
        <img src={logoLab} alt="logo lab" height="50px" />
      </p>
    )
  }

  let logos;
  if (props.subprincipal === true) {
    {/* logos = logosSubprincipal()*/}
    logos = logosPrincipal()
  } else {
    logos = logosPrincipal()
  }

  const componente = (
    <>
      <ul className={styles.listLinks}>
        <li>
          <NavLink to="/">Inicio</NavLink>
        </li>
        <li onClick={toggleDropdown}>
          <a>Alumnos</a>
          {dropdownVisible && (
            <ul className={styles.nestListLinks}>
              <li>
                <NavLink to="/Alumnos24B">
                  Semestre 24B, agosto-diciembre 2024
                </NavLink>
              </li>
              <li>
                <NavLink to="/Alumnos24A">
                  Semestre 24A, enero-julio 2024
                </NavLink>
              </li>
              <li>
                <NavLink to="/Alumnos23B">
                  Semestre 23B, agosto-diciembre 2023
                </NavLink>
              </li>
              <li>
                <NavLink to="/Alumnos23A">
                  Semestre 23A, enero-julio 2023
                </NavLink>
              </li>
              <li>
                <NavLink to="/estancias">Estancias</NavLink>
              </li>
            </ul>
          )}
        </li>
        <li onClick={toggleDropdownInvestigacion}>
          <a>Investigación</a>
          {dropdownInvestigacion && (
            <ul className={styles.nestListLinks}>
              <li>
                <NavLink to="/investigacion">
                  Post-doctoral
                </NavLink>
              </li>
              <li>
                Bases de Datos
              </li>
              <li>
                <NavLink to="Espectros">
                  Espectros
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink to="/tesis">Propuestas de Tesis</NavLink>
        </li>
        <li onClick={toggleDropdownEventos}>
          <a>Eventos</a>
          {dropdownEventos && (
            <ul className={styles.nestListLinks}>
              <li> <a href="https://femexrobotica.org/eir2024-2025/" className={styles.link}>Escuela de Invierno de Robótica</a> </li>
              <li> <a href="https://sites.google.com/view/qiskit-fallfest-cdmx24/inicio" className={styles.link}>Qiskit Fall Fest 2024</a> </li>
              <li> <a href="https://indico.buap.mx/event/97/" className={styles.link}>Oportunidades en la Computación Quántica 2024</a></li>
              <li>  <NavLink to="/Qiskit2023">Qiskit Fall Fest 2023</NavLink> </li>
              <li> <a href="https://proyectosrym.cic.ipn.mx/qcoloquio/" className={styles.link}>3er Coloquio de cómputo cuántico</a> </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink to="/galeria">Galería</NavLink>
        </li>
      </ul>
    </>
  )

  return (
    <>
      {logos}
      {componente}
    </>
  );
};
