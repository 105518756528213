

import React, { useEffect, useState } from 'react'

import styles from "../css/HomePage.module.css";
import d1 from "../images/Qiskit2023/Convocatoria.png";
import d4 from "../images/Qiskit2023/Primera_Semana.png";
import d6 from "../images/Qiskit2023/Segunda_Semana.png";
import d8 from "../images/Qiskit2023/Tercera_Semana.png";

import g1 from "../images/Qiskit2023/Ganador_01.png";
import g2 from "../images/Qiskit2023/Ganador_02.png";
import g3 from "../images/Qiskit2023/Ganador_03.png";

import c1 from "../images/Qiskit2023/Conf_10_23_02.png";
import c2 from "../images/Qiskit2023/Conf_10_23_04.png";
import c3 from "../images/Qiskit2023/Conf_10_24_01.png";
import c4 from "../images/Qiskit2023/Conf_10_24_02.png";
import c5 from "../images/Qiskit2023/Conf_10_24_03.png";
import c6 from "../images/Qiskit2023/Conf_10_25_01.png";
import c7 from "../images/Qiskit2023/Conf_10_25_02.png";
import c8 from "../images/Qiskit2023/Conf_10_26_01.png";
import c9 from "../images/Qiskit2023/Conf_10_26_03.png";
import c10 from "../images/Qiskit2023/Conf_10_27_02.png";
import c11 from "../images/Qiskit2023/Conf_10_30_01.png";
import c12 from "../images/Qiskit2023/Conf_10_30_02.png";
import c13 from "../images/Qiskit2023/Conf_10_30_03.png";
import c14 from "../images/Qiskit2023/Conf_10_30_04.png";
import c15 from "../images/Qiskit2023/Conf_10_30_05.png";
import c16 from "../images/Qiskit2023/Conf_10_30_06.png";

import c17 from "../images/Qiskit2023/Conf_10_31_01.png";
import c18 from "../images/Qiskit2023/Conf_10_31_03.png";
import c19 from "../images/Qiskit2023/Conf_10_31_04.png";
import c20 from "../images/Qiskit2023/Conf_10_31_05.png";
import c21 from "../images/Qiskit2023/Conf_10_31_06.png";

import c22 from "../images/Qiskit2023/Conf_11_01_01.png";
import c23 from "../images/Qiskit2023/Conf_11_01_02.png";
import c24 from "../images/Qiskit2023/Conf_11_01_03.png";
import c25 from "../images/Qiskit2023/Conf_11_01_04.png";
import c26 from "../images/Qiskit2023/Conf_11_01_05.png";
import c27 from "../images/Qiskit2023/Conf_11_01_06.png";

import c28 from "../images/Qiskit2023/Conf_11_02_02.png";
import c29 from "../images/Qiskit2023/Conf_11_02_03.png";
import c30 from "../images/Qiskit2023/Conf_11_02_06.png";

import c31 from "../images/Qiskit2023/Conf_11_03_01.png";
import c32 from "../images/Qiskit2023/Conf_11_03_02.png";
import c33 from "../images/Qiskit2023/Conf_11_03_03.png";
import c34 from "../images/Qiskit2023/Conf_11_03_04.png";
import c35 from "../images/Qiskit2023/Conf_11_03_05.png";


<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/featherlight/1.7.13/featherlight.min.css"></link>

export const Qiskit2023 = () => {
  return (
    <>
      <div align= "center"   class="col-md-8">
        <h1>Qiskit Fall Fest 2023</h1>
      </div>
      <div>
    <h1>&nbsp;</h1>
  </div>
      <div align="center">
        <a href="#" data-featherlight={d1}><img src={d1} width="33%" height="" class="img-fluid mx-auto d-block  " alt="Convocatoria Qiskit Fall Fest 2023"/></a>
      </div>
      <div>
    <img src={d4} width="32%" alt="Agenda Primera Semana - Qiskit Fall Fest"/> &nbsp;
	<img src={d6} width="32%" alt="Agenda Segunda Semana - Qiskit Fall Fest"/> &nbsp;
	<img src={d8} width="32%" alt="Agenda Tercera Semana - Qiskit Fall Fest"/> </div>
  <div>
    <h1>&nbsp;</h1>
  </div>
  <div align="center">
    <h1>Ganadores Hackathon</h1>
  </div>
  <div>
    <h1>&nbsp;</h1>
  </div>
  <div><a href='https://www.youtube.com/watch?v=fAF4OvonW8Y'  target="new">
    <img src={g1} width="32%" alt="Ganador 1er lugar Hackathon - Qiskit Fall Fest"/> &nbsp;
	<img src={g2} width="32%" alt="Ganador 2ndo lugar Hackathon - Qiskit Fall Fest"/> &nbsp;
	<img src={g3} width="32%" alt="Ganador 3er lugar Hackathon - Qiskit Fall Fest"/> 
  </a></div>
  <div align="center"></div>
  <div align="center">
    <h1>Expositores</h1>
  </div>
  <div  class="col-md-2"></div>
  <div  class="col-md-8">
  <div class="panel-group ficha-collapse" id="accordion">
    <div class="panel panel-default">
      {/*23 de Octubre - Panel 01*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-01" aria-expanded="true" aria-controls="panel-01">
          23 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-01"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-01">
        <div class="panel-body">
          <div class="col-md-4"><img src={c1} width="100%"  alt="23 Octubre - Pablo Conte"/></div>
          <div class="col-md-4"><img src={c2} width="100%"  alt="23 Octubre - Ricardo Quispe"/></div>
        </div>
      </div>
      {/*24 de Octubre - Panel 02*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-02" aria-expanded="true" aria-controls="panel-02">
          24 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-02"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-02">
        <div class="panel-body">
          <div class="col-md-4"><img src={c3} width="100%"  alt="24 Octubre - Miguel González Martínez"/></div>
          <div class="col-md-4"><img src={c4} width="100%"  alt="24 Octubre - Kevin Joven"/></div>
          <div class="col-md-4"><img src={c5} width="100%"  alt="24 Octubre - Mauricio Gómez Viloria"/></div>
        </div>
      </div>
      {/*25 de Octubre - Panel-03*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-03" aria-expanded="true" aria-controls="panel-03">
          25 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-03"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-03">
        <div class="panel-body">
          <div class="col-md-4"><img src={c6} width="100%"  alt="25 Octubre - Claudia Zendejas"/></div>
          <div class="col-md-4"><img src={c7} width="100%"  alt="25 Octubre - Alberto Maldonado Romo"/></div>
        </div>
      </div>
      {/*26 de Octubre - Panel-04*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-04" aria-expanded="true" aria-controls="panel-04">
          26 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-04"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-04">
        <div class="panel-body">
          <div class="col-md-4"><img src={c8} width="100%"  alt="26 Octubre - Daniel Guijo"/></div>
          <div class="col-md-4"><img src={c9} width="100%"  alt="26 Octubre - Victor Onofre"/></div>
        </div>
      </div>
      {/*27 de Octubre - Panel-05*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-05" aria-expanded="true" aria-controls="panel-05">
          27 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-05"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-05">
        <div class="panel-body">
          <div class="col-md-4"><img src={c10} width="100%"  alt="27 Octubre - Daniel Sierra Sosa"/></div>
        </div>
      </div>
      {/*30 de Octubre - Panel-06*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-06" aria-expanded="true" aria-controls="panel-06">
          30 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-06"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-06">
        <div class="panel-body">
          <div class="col-md-4"><img src={c11} width="100%"  alt="30 Octubre - Ezequiel Rodriguez Chiacchio"/></div>
          <div class="col-md-4"><img src={c12} width="100%"  alt="30 Octubre - Alejandro Montañez"/></div>
          <div class="col-md-4"><img src={c13} width="100%"  alt="30 Octubre - Alejandro Montañez"/></div>
          <div class="col-md-4"><img src={c14} width="100%"  alt="30 Octubre - Aitor Moreno Fernández de Leceta"/></div>
          <div class="col-md-4"><img src={c15} width="100%"  alt="30 Octubre - David Bernal"/></div>
          <div class="col-md-4"><img src={c16} width="100%"  alt="30 Octubre - Ryan Hill"/></div>
        </div>
      </div>
      {/*31 de Octubre - Panel-07*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-07" aria-expanded="true" aria-controls="panel-07">
          31 de Octubre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-07"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-07">
        <div class="panel-body">
          <div class="col-md-4"><img src={c17} width="100%"  alt="31 Octubre - Siddhartha Morales"/></div>
          <div class="col-md-4"><img src={c18} width="100%"  alt="31 Octubre - Abdullah Khalid"/></div>
          <div class="col-md-4"><img src={c19} width="100%"  alt="31 Octubre - Juan Francisco Rodríguez"/></div>
          <div class="col-md-4"><img src={c20} width="100%"  alt="31 Octubre - Manuel Montoya"/></div>
          <div class="col-md-4"><img src={c21} width="100%"  alt="31 Octubre - Abuzer Yakaryilmaz"/></div>
        </div>
      </div>
      {/*1 de Noviembre - Panel-08*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-08" aria-expanded="true" aria-controls="panel-08">
          1 de Noviembre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-08"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-08">
        <div class="panel-body">
          <div class="col-md-4"><img src={c22} width="100%"  alt="1ero Noviembre - Alberto Maldonado Romo"/></div>
          <div class="col-md-4"><img src={c23} width="100%"  alt="1ero Noviembre - Catalina Albornoz"/></div>
          <div class="col-md-4"><img src={c24} width="100%"  alt="1ero Noviembre - Alejandro Mata Ali"/></div>
          <div class="col-md-4"><img src={c25} width="100%"  alt="1ero Noviembre - Daniel Sierra Sosa"/></div>
          <div class="col-md-4"><img src={c26} width="100%"  alt="1ero Noviembre - Misty Wahl"/></div>
          <div class="col-md-4"><img src={c27} width="100%"  alt="1ero Noviembre - Luciano Pereira"/></div>
        </div>
      </div>
      {/*2 de Noviembre - Panel-09*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-09" aria-expanded="true" aria-controls="panel-09">
          2 de Noviembre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-09"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-09">
        <div class="panel-body">
          <div class="col-md-4"><img src={c28} width="100%"  alt="2 Noviembre - Mario Dagrada"/></div>
          <div class="col-md-4"><img src={c29} width="100%"  alt="2 Noviembre - María Gragera Garcés"/></div>
          <div class="col-md-4"><img src={c30} width="100%"  alt="2 Noviembre - Raúl Palacios"/></div>
        </div>
      </div>
      {/*3 de Noviembre - Panel-10*/}
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-parent="#accordion" data-toggle="collapse" href="#panel-10" aria-expanded="true" aria-controls="panel-10">
          3  de Noviembre
          </a>
        </h4>
        <button type="button" class="collpase-button collapsed" data-parent="#accordion" data-toggle="collapse" href="#panel-10"></button>
      </div>
      <div class="panel-collapse collapse in" id="panel-10">
        <div class="panel-body">
          <div class="col-md-4"><img src={c31} width="100%"  alt="3 Noviembre - Enrique Solano"/></div>
          <div class="col-md-4"><img src={c32} width="100%"  alt="3 Noviembre - Eric Arellano"/></div>
          <div class="col-md-4"><img src={c33} width="100%"  alt="3 Noviembre - Español e Inglés, la importancia de los idiomas"/></div>
          <div class="col-md-4"><img src={c34} width="100%"  alt="3 Noviembre - Casos de éxito de hispanohablantes"/></div>
          <div class="col-md-4"><img src={c35} width="100%"  alt="3 Noviembre - Mujeres y No Binaries"/></div>
        </div>
      </div>
      {/******* FIN ******/}
    </div>
  </div>
  </div>

    </>
  
  );
}