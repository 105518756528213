export const propuestas_tesis = [
  {
    ID: 1,
    Nombre: "Dr. Juan Humberto Sossa Azuela",
    Correo: "humbertosossa@gmail.com",
    Tesis: [],
  },
  {
    ID: 2,
    Nombre: "Dra. Elsa Rubio Espino",
    Correo: "erubio@cic.ipn.mx",
    Tesis: [],
  },
  {
    ID: 3,
    Nombre: "Dr. Erik Zamora",
    Correo: "ezamora1981@gmail.com",
    Tesis: [],
  },
  {
    ID: 4,
    Nombre: "Dr. Jesús Yaljá Montiel Pérez",
    Correo: "yalja@ipn.mx",
    Tesis: [
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Las series de tiempo caóticas pueden ser datos financieros, de signos vitales médicos, etc. En cualquier caso deben de ser analizadas, procesadas y el problema a resolver es predecir los estados futuros de dichas señales usando redes neuronales recurrentes o arquitecturas híbridas.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Caracterización de comportamiento humano en zonas limitadas",
        Descripcion: "Con base a la adquisición de características de comportamiento con RN se crean patrones de que se asocia a individuos para poder usarse en aplicaciones de ciudades inteligentes.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Los estados energéticos de las moléculas simples que tienen un comportamiento cuántico y se pueden modelar mediante algoritmos de la cómputación cuántica.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Proyectos relacionados a robots con aplicaciones de servicio como la vigilancia, limpieza, monitorización etc. Así como robótica para servicio en el ámbito médico como la extracción de sangre, cosido de heridas, etc.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Proyectos donde los algoritmos se implementen en un arreglo para FPGAs y se realice procesamiento en hardware. como lo puede ser reconocimiento de objetos en video, clasificación, predicción etc.",
      },
      {
        Nivel:"Maestría y doctorado",
        Tema: "Predicción de series de tiempo caóticas",
        Descripcion: "Implementación de algoritmos cuánticos para predicción en series de tiempo, reconocimient y clasificación en imágenes y señales, etc. Procesos de reacciones químicas simples.",
      }

    ],
  },
  {
    ID: 5,
    Nombre: "Dr. Ponciano Jorge Escamilla Ambrosio",
    Correo: "pescamillaa@ipn.mx",
    Tesis: [],
  },
  {
    ID: 6,
    Nombre: "Dr. Tat'y Muata Velu",
    Correo: "tmwata@cic.ipn.mx",
    Tesis: [],
  }
];
