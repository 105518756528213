import { alumnos_data } from "../data/alumnos-data";
import { useState, useEffect } from 'react';
import styles2 from "../css/AlumnoPage.module.css";

function ListaAlumnos(props) {

    const [dataLista, setdataLista] = useState([]);

    useEffect(() => {
        setdataLista(alumnos_data)
    }, [])

    if (dataLista.filter(element => element.Semestre === props.Semestre).length === 0) {
        return <h1 style={{ textAlign: "center" }}>No hay alumnos inscritos en este semestre</h1>
    }

    return (
        <>
            {
                dataLista.filter(element => element.Semestre === props.Semestre).map((dato) => (
                    <div className={styles2.cuadroAlumnos} key={dato.ID}>
                        <div>
                            <img src={dato.Foto}
                                alt="Foto de alumno" />
                        </div>
                        <div>
                            <p>{dato.Nombre} </p>
                            <p>{dato.Correo} </p>
                        </div>
                        <div>
                            <p style={{ align: "left" }}>Programa: {dato.Programa} </p>
                            <p>Tesis: "{dato.Tesis}"</p>
                        </div>
                        <div>
                            {dato.Seminario!=='xx/xx/xxxx' && <p>Presentación en el seminario del {dato.Seminario}</p>}
                            <p><a href={`/pdf/exposiciones_de_seminario/${dato.Link}.pdf`} >{dato.Link !== "" ? "Link a PDF." : ""}</a></p>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default ListaAlumnos;