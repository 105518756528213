
import ListaGrupoInvestigacion from "../components/ListInvestigacion";
import styles2 from "../css/Tesis.module.css";

export const Investigacion = () => {
  return (
    <>
      <div>
      <h3 className={styles2.homepageSubtitulo}>Grupo de trabajo</h3>
      <ListaGrupoInvestigacion />
      </div>
    </>
  );
}