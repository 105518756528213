/* lista tipo JSON, 
    en esta lista se encuentran los datos de los alumnos  */
export const alumnos_data = [
    {
        ID: 1,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Elizabeth Lopez Lozada",
        Correo: "lizzy_silverthorns@hotmail.com",
        Programa: "DCC",
        Tesis: "Reconocimiento de acciones humanas usando inteligencia artificial",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 2,
        Foto: require("../images/fotos_Alumnos/Jose_Navarro.jpg"),
        Nombre: "José Angel Martinez Navarro",
        Correo: "josekun13@gmail.com",
        Programa: "DCC",
        Tesis: "Emotion Recognition in Audio using Spiking Neural Networks ",
        Seminario: "xx/xx/2022",/*algunas fechas se encuentran en los mismos PDF */
        Link: "",/*este dato es para el pdf de cada alumno, solo se requiere el nombre, sin extencion, y agregar el pdf a la carpeta del filezilla */
        Semestre: "23A",
    },
    {
        ID: 3,
        Foto: require("../images/fotos_Alumnos/Rodrigo.jpg"),
        Nombre: "Rodrigo Roman",
        Correo: "rodrigo_0045@hotmail.com",
        Programa: "DCC",
        Tesis: "Contributions to neural networks with dendritic processing: a geometric approach",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 4,
        Foto: require("../images/fotos_Alumnos/Alejandro.jpg"),
        Nombre: "Alejandro Hernández Herrera",
        Correo: "alex_1@mac.com",
        Programa: "DCC",
        Tesis: "Modelo de aprendizaje automático para detectar colisiones inesperadas en el ciclismo urbano",
        Seminario: "xx/03/2023",
        Link: "Presentacion_seminario_PhD_v6",
        Semestre: "23A",
    },
    {
        ID: 5,
        Foto: require("../images/fotos_Alumnos/Alberto.jpg"),
        Nombre: "Alberto Maldonado Romo",
        Correo: "alberto.maldo1312@gmail.com",
        Programa: "DCC",
        Tesis: "Algoritmo de aprendizaje de máquina cuántico supervisado",
        Seminario: "xx/xx/2022",
        Link: "alberto_maldonado_thesis_advance_fifth_semester",
        Semestre: "23A",
    },
    {
        ID: 6,
        Foto: require("../images/fotos_Alumnos/David.jpg"),
        Nombre: "David Perez Martinez ",
        Correo: "d4v1dp3@gmail.com",
        Programa: "DCC",
        Tesis: "Generación de Numeros aleatorios usando FPGA",
        Seminario: "15/02/2023",
        Link: "david_perez_dcc_seminario-RYM-15feb2023v2",
        Semestre: "23A",
    },
    {
        ID: 7,
        Foto: require("../images/fotos_Alumnos/Mario.jpg"),
        Nombre: "Mario Alfredo Ibarra Carrillo",
        Correo: "buzonibarra71@gmail.com",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 8,
        Foto: require("../images/fotos_Alumnos/Jorge_Alejandro.jpg"),
        Nombre: "Jorge Alejandro Juárez Lora",
        Correo: "jjuarezl2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Diseño de plataforma neuromorphica para sistemas roboticos",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 9,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Jonathan Axel Cruz Vazque",
        Correo: "axl.cvazquez@gmail.com",
        Programa: "DCC",
        Tesis: "Modelo emotivo humano mediante señales EEG",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 10,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Karen Olivia Martínez Clavería",
        Correo: "kmartinezc2022@cic.ipn.mx",
        Programa: "MCIC",
        Tesis: "Estabilización de la posición de un dron con arquitectura",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 11,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Donaldo García Juanillo",
        Correo: "donigj00@gmail.com",
        Programa: "DCC",
        Tesis: "Red neuronal de función de base radial convolucional usada para el modelado de la demanda de energía eléctrica por hora",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 12,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "José Abraham Tellez Morales",
        Correo: "jtellezm2022@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 13,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel de Jesús Martínez Felipe",
        Correo: "mmartinezf2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Emparejamiento de bloques de imágenes ruidosas con base en la medida de disimilitud en el dominio de la transformada de coseno discreta",
        Seminario: "xx/03/2023",
        Link: "jesus",
        Semestre: "23A",
    },
    {
        ID: 14,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Raziel López Escamilla",
        Correo: "razielescamilla@outlook.com",
        Programa: "",
        Tesis: "",
        Seminario: "24/03/2023",
        Link: "RLESem6-1",
        Semestre: "23A",
    },
    {
        ID: 15,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Saulo Abraham Gante Díaz",
        Correo: "gantesaulo@gmail.com",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 16,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Alain Gomez Cabrera",
        Correo: "alngoca@gmail.com",
        Programa: "",
        Tesis: "A digital twin of buildings for structural health monitoring",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 17,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "César Castrejón Peralta",
        Correo: "castrejonp2021@cic.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 18,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Diana Patricia Barragán Vázquez",
        Correo: "dbarraganv2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Modelado y simulación del proceso de extracción robotizada de sangre mediante técnicas de IA",
        Seminario: "10/02/2023",
        Link: "Presentacion_10_febrero_2023_seminario",
        Semestre: "23A",
    },
    {
        ID: 19,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel Aarón Galicia Zainos",
        Correo: "galiciaz2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Navegación robótica basada en visión artificial mediante modelos del mundo autosupervisados",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 20,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Gerardo Álvarez Hernández",
        Correo: "galvarezh1400@alumno.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 21,
        Foto: require("../images/fotos_Alumnos/Luis.jpg"),
        Nombre: "Luis Enrique Andrade Gorjoux",
        Correo: "landradeg2022@cic.ipn.mx",
        Programa: "MCIC",
        Tesis: "Predicción de algunos índices bursátiles en el mercado de valores mexicano usando computación cuántica",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "23A",
    },
    {
        ID: 22,
        Foto: require("../images/fotos_Alumnos/jordi.jpeg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Jordi Fabián Gonzáles Contreras",
        Correo: "jgonzalesc2023@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Chaotic time series forecasting using quantum long short-term memory",
        Seminario: "01/12/2024",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 23,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Elizabeth Lopez Lozada",
        Correo: "lizzy_silverthorns@hotmail.com",
        Programa: "DCC",
        Tesis: "Reconocimiento de acciones humanas usando inteligencia artificial",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 24,
        Foto: require("../images/fotos_Alumnos/Jose_Navarro.jpg"),
        Nombre: "José Angel Martinez Navarro",
        Correo: "josekun13@gmail.com",
        Programa: "DCC",
        Tesis: "Emotion Recognition in Audio using Spiking Neural Networks ",
        Seminario: "xx/xx/xxxx",/*algunas fechas se encuentran en los mismos PDF */
        Link: "",/*este dato es para el pdf de cada alumno, solo se requiere el nombre, sin extencion, y agregar el pdf a la carpeta del filezilla */
        Semestre: "23B",
    },
    {
        ID: 25,
        Foto: require("../images/fotos_Alumnos/Rodrigo.jpg"),
        Nombre: "Rodrigo Roman",
        Correo: "rodrigo_0045@hotmail.com",
        Programa: "DCC",
        Tesis: "Contributions to neural networks with dendritic processing: a geometric approach",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 26,
        Foto: require("../images/fotos_Alumnos/Alejandro.jpg"),
        Nombre: "Alejandro Hernández Herrera",
        Correo: "alex_1@mac.com",
        Programa: "DCC",
        Tesis: "Modelo de aprendizaje automático para detectar colisiones inesperadas en el ciclismo urbano",
        Seminario: "xx/xx/xxxx",
        Link: "Presentacion_seminario_PhD_v6",
        Semestre: "23B",
    },
    {
        ID: 27,
        Foto: require("../images/fotos_Alumnos/Alberto.jpg"),
        Nombre: "Alberto Maldonado Romo",
        Correo: "alberto.maldo1312@gmail.com",
        Programa: "DCC",
        Tesis: "Algoritmo de aprendizaje de máquina cuántico supervisado",
        Seminario: "xx/xx/xxxx",
        Link: "alberto_maldonado_thesis_advance_fifth_semester",
        Semestre: "23B",
    },
    {
        ID: 28,
        Foto: require("../images/fotos_Alumnos/David.jpg"),
        Nombre: "David Perez Martinez ",
        Correo: "d4v1dp3@gmail.com",
        Programa: "DCC",
        Tesis: "Generación de Numeros aleatorios usando FPGA",
        Seminario: "xx/xx/xxxx",
        Link: "david_perez_dcc_seminario-RYM-15feb2023v2",
        Semestre: "23B",
    },
    {
        ID: 29,
        Foto: require("../images/fotos_Alumnos/Mario.jpg"),
        Nombre: "Mario Alfredo Ibarra Carrillo",
        Correo: "buzonibarra71@gmail.com",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 30,
        Foto: require("../images/fotos_Alumnos/Jorge_Alejandro.jpg"),
        Nombre: "Jorge Alejandro Juárez Lora",
        Correo: "jjuarezl2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Diseño de plataforma neuromorphica para sistemas roboticos",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 31,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Jonathan Axel Cruz Vazque",
        Correo: "axl.cvazquez@gmail.com",
        Programa: "DCC",
        Tesis: "Modelo emotivo humano mediante señales EEG",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 32,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Karen Olivia Martínez Clavería",
        Correo: "kmartinezc2022@cic.ipn.mx",
        Programa: "MCIC",
        Tesis: "Estabilización de la posición de un dron con arquitectura",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 33,
        Foto: require("../images/fotos_Alumnos/donaldo.png"),
        Nombre: "Donaldo García Juanillo",
        Correo: "donigj00@gmail.com",
        Programa: "DCC",
        Tesis: "Red neuronal de función de base radial convolucional usada para el modelado de la demanda de energía eléctrica por hora",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 34,
        Foto: require("../images/Estancias/jose.jpg"),
        Nombre: "José Abraham Tellez Morales",
        Correo: "jtellezm2022@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 35,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel de Jesús Martínez Felipe",
        Correo: "mmartinezf2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Emparejamiento de bloques de imágenes ruidosas con base en la medida de disimilitud en el dominio de la transformada de coseno discreta",
        Seminario: "xx/xx/xxxx",
        Link: "jesus",
        Semestre: "23B",
    },
    {
        ID: 37,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Alain Gomez Cabrera",
        Correo: "alngoca@gmail.com",
        Programa: "",
        Tesis: "A digital twin of buildings for structural health monitoring",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 38,
        Foto: require("../images/fotos_Alumnos/cesar.png"),
        Nombre: "César Castrejón Peralta",
        Correo: "castrejonp2021@cic.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 39,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Diana Patricia Barragán Vázquez",
        Correo: "dbarraganv2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Modelado y simulación del proceso de extracción robotizada de sangre mediante técnicas de IA",
        Seminario: "xx/xx/xxxx",
        Link: "Presentacion_10_febrero_2023_seminario",
        Semestre: "23B",
    },
    {
        ID: 40,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel Aarón Galicia Zainos",
        Correo: "galiciaz2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Navegación robótica basada en visión artificial mediante modelos del mundo autosupervisados",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 41,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Gerardo Álvarez Hernández",
        Correo: "galvarezh1400@alumno.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 42,
        Foto: require("../images/fotos_Alumnos/Luis.jpg"),
        Nombre: "Luis Enrique Andrade Gorjoux",
        Correo: "landradeg2022@cic.ipn.mx",
        Programa: "MCIC",
        Tesis: "Predicción de algunos índices bursátiles en el mercado de valores mexicano usando computación cuántica",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "23B",
    },
    {
        ID: 43,
        Foto: require("../images/fotos_Alumnos/Jorge_Alejandro.jpg"),
        Nombre: "Jorge Alejandro Juárez Lora",
        Correo: "jjuarezl2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Diseño de plataforma neuromorphica para sistemas roboticos",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 44,
        Foto: require("../images/fotos_Alumnos/Mario.jpg"),
        Nombre: "Mario Alfredo Ibarra Carrillo",
        Correo: "buzonibarra71@gmail.com",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 45,
        Foto: require("../images/fotos_Alumnos/Alberto.jpg"),
        Nombre: "Alberto Maldonado Romo",
        Correo: "alberto.maldo1312@gmail.com",
        Programa: "DCC",
        Tesis: "Algoritmo de aprendizaje de máquina cuántico supervisado",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 46,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Elizabeth Lopez Lozada",
        Correo: "lizzy_silverthorns@hotmail.com",
        Programa: "DCC",
        Tesis: "Reconocimiento de acciones humanas usando inteligencia artificial",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 47,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Jonathan Axel Cruz Vázquez",
        Correo: "axl.cvazquez@gmail.com",
        Programa: "DCC",
        Tesis: "Modelo emotivo humano mediante señales EEG",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 48,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Saulo Abraham Gante Díaz",
        Correo: "gantesaulo@gmail.com",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 49,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Víctor Adonis Hernández Almendra",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 50,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Abel Alejandro Rubín Alvarado",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 51,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Angel Antonio Carreón Torres",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 52,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Liliabet Vega Ledesma",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 53,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Irialys Calderón Morales",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 54,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Lázaro Raudal González Leyva",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 55,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Rubén Gutiérrez Manso",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 56,
        Foto: require("../images/fotos_Alumnos/jordi.jpeg"),
        Nombre: "Jordi Fabián Gonzáles Contreras",
        Correo: "jgonzalesc2023@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Chaotic time series forecasting using quantum long short-term memory",
        Seminario: "01/12/2024",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 57,
        Foto: require("../images/fotos_Alumnos/David.jpg"),
        Nombre: "David Pérez Martínez ",
        Correo: "d4v1dp3@gmail.com",
        Programa: "DCC",
        Tesis: "Generación de Numeros aleatorios usando FPGA",
        Seminario: "15/02/2023",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 58,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "César Castrejón Peralta",
        Correo: "castrejonp2021@cic.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 59,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel Aarón Galicia Zainos",
        Correo: "galiciaz2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Navegación robótica basada en visión artificial mediante modelos del mundo autosupervisados",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 60,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Alain Gomez Cabrera",
        Correo: "alngoca@gmail.com",
        Programa: "",
        Tesis: "A digital twin of buildings for structural health monitoring",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 61,
        Foto: require("../images/fotos_Alumnos/Alejandro.jpg"),
        Nombre: "Alejandro Hernández Herrera",
        Correo: "alex_1@mac.com",
        Programa: "DCC",
        Tesis: "Modelo de aprendizaje automático para detectar colisiones inesperadas en el ciclismo urbano",
        Seminario: "xx/03/2023",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 62,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "José Abraham Tellez Morales",
        Correo: "jtellezm2022@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 63,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Gustavo Mandujano",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 64,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Carlos Murillo Aguilar",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 65,
        Foto: require("../images/fotos_Alumnos/FotoArvizuVelazquez.png"),
        Nombre: "Arturo Arvizu Velázquez",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 66,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Ariel Plasencia Díaz",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 67,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Joel Patricio José López",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 68,
        Foto: require("../images/fotos_Alumnos/JorgeSaavedra.png"),
        Nombre: "Jorge Saavedra",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24B",
    },
    {
        ID: 69,
        Foto: require("../images/fotos_Alumnos/Jorge_Alejandro.jpg"),
        Nombre: "Jorge Alejandro Juárez Lora",
        Correo: "jjuarezl2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Diseño de plataforma neuromorphica para sistemas roboticos",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 70,
        Foto: require("../images/fotos_Alumnos/Mario.jpg"),
        Nombre: "Mario Alfredo Ibarra Carrillo",
        Correo: "buzonibarra71@gmail.com",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 71,
        Foto: require("../images/fotos_Alumnos/Alberto.jpg"),
        Nombre: "Alberto Maldonado Romo",
        Correo: "alberto.maldo1312@gmail.com",
        Programa: "DCC",
        Tesis: "Algoritmo de aprendizaje de máquina cuántico supervisado",
        Seminario: "xx/xx/2022",
        Link: "alberto_maldonado_thesis_advance_fifth_semester",
        Semestre: "24A",
    },
    {
        ID: 72,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Elizabeth Lopez Lozada",
        Correo: "lizzy_silverthorns@hotmail.com",
        Programa: "DCC",
        Tesis: "Reconocimiento de acciones humanas usando inteligencia artificial",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 73,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Jonathan Axel Cruz Vazque",
        Correo: "axl.cvazquez@gmail.com",
        Programa: "DCC",
        Tesis: "Modelo emotivo humano mediante señales EEG",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 74,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Saulo Abraham Gante Díaz",
        Correo: "gantesaulo@gmail.com",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 75,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Víctor Adonis Hernández Almendra",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 76,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Abel Alejandro Rubín Alvarado",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 77,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Angel Antonio Carreón Torres",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 78,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Liliabet Vega Ledesma",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 79,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Irialys Calderón Morales",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 80,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Lázaro Raudal González Leyva",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 81,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Rubén Gutiérrez Manso",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 82,
        Foto: require("../images/fotos_Alumnos/jordi.jpeg"),/*agregar la imagen a la ruta, y cambiar el nombre corespondiente */
        Nombre: "Jordi Fabián Gonzáles Contreras",
        Correo: "jgonzalesc2023@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Chaotic time series forecasting using quantum long short-term memory",
        Seminario: "01/12/2024",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 83,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel de Jesús Martínez Felipe",
        Correo: "mmartinezf2020@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "Emparejamiento de bloques de imágenes ruidosas con base en la medida de disimilitud en el dominio de la transformada de coseno discreta",
        Seminario: "xx/03/2023",
        Link: "jesus",
        Semestre: "24A",
    },
    {
        ID: 84,
        Foto: require("../images/fotos_Alumnos/David.jpg"),
        Nombre: "David Perez Martinez ",
        Correo: "d4v1dp3@gmail.com",
        Programa: "DCC",
        Tesis: "Generación de Numeros aleatorios usando FPGA",
        Seminario: "15/02/2023",
        Link: "david_perez_dcc_seminario-RYM-15feb2023v2",
        Semestre: "24A",
    },
    {
        ID: 85,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "César Castrejón Peralta",
        Correo: "castrejonp2021@cic.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 86,
        Foto: require("../images/fotos_Alumnos/Luis.jpg"),
        Nombre: "Luis Enrique Andrade Gorjoux",
        Correo: "landradeg2022@cic.ipn.mx",
        Programa: "MCIC",
        Tesis: "Predicción de algunos índices bursátiles en el mercado de valores mexicano usando computación cuántica",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 87,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Gerardo Álvarez Hernández",
        Correo: "galvarezh1400@alumno.ipn.mx",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 88,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Isai Garrido",
        Correo: "",
        Programa: "",
        Tesis: "",
        Seminario: "xx/xx/xxxx",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 89,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Miguel Aarón Galicia Zainos",
        Correo: "galiciaz2021@cic.ipn.mx",
        Programa: "",
        Tesis: "Navegación robótica basada en visión artificial mediante modelos del mundo autosupervisados",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 90,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "Alain Gomez Cabrera",
        Correo: "alngoca@gmail.com",
        Programa: "",
        Tesis: "A digital twin of buildings for structural health monitoring",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
    {
        ID: 91,
        Foto: require("../images/fotos_Alumnos/Alejandro.jpg"),
        Nombre: "Alejandro Hernández Herrera",
        Correo: "alex_1@mac.com",
        Programa: "DCC",
        Tesis: "Modelo de aprendizaje automático para detectar colisiones inesperadas en el ciclismo urbano",
        Seminario: "xx/03/2023",
        Link: "Presentacion_seminario_PhD_v6",
        Semestre: "24A",
    },
    {
        ID: 92,
        Foto: require("../images/fotos_Alumnos/nada.jpg"),
        Nombre: "José Abraham Tellez Morales",
        Correo: "jtellezm2022@cic.ipn.mx",
        Programa: "DCC",
        Tesis: "",
        Seminario: "xx/xx/2022",
        Link: "",
        Semestre: "24A",
    },
];