import { Route, Routes, useLocation } from 'react-router-dom';
import { ListLinksMenu } from "./templates/NavBar";
import { Footer } from "./templates/Footer";
import { HomePage } from './pages/HomePage';
import { Alumnos } from './pages/Alumnos';
import { Proyectos } from './pages/Proyectos';
import { Tesis } from './pages/Tesis';
import { Estancias } from './pages/Estancias';
import { Delfin } from './pages/Delfin'
import { PDF } from './pages/PDF';
import styles from "./css/NavBar.module.css";
import './App.css';
import Galeria from './pages/Galeria';
import {Investigacion} from './pages/Investigacion';
import { Qiskit2023 } from './pages/Qiskit2023';
import {Espectros} from './pages/BD/Espectros'
import { Footer_ipn } from './templates/Footer_ipn';
import {Social} from './templates/Social';
import {Menu_Lab} from './templates/Menu_Lab';

const About = ({ nombre }) => <h3 style={{ textAlign: 'center', marginTop: 50 }}>{nombre}</h3>;
function App() {
  const location = useLocation();
  return (
    
    <div>
      {/*location.pathname !== '/' && location.pathname !== '/programa-delfin' && <header className={styles.navbar}><ListLinksMenu subprincipal={true} styles={styles} /></header>*/}
      <Menu_Lab/>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/seminario' element={<About nombre='Página seminario' />} />
        <Route path='/reconocimientos' element={<About nombre='Página reconocimientos' />} />
        <Route path='/proyectos' element={<Proyectos />} />
        <Route path='/estancias' element={<Estancias />} />
        <Route path='/tesis' element={<Tesis />} />
        <Route path='/alumnos24B' element={<Alumnos />} />
        <Route path='/alumnos24A' element={<Alumnos />} />
        <Route path='/alumnos23B' element={<Alumnos />} />
        <Route path='/alumnos23A' element={<Alumnos />} />
        <Route path='/alumnos/:PDF' element={<PDF />} />
        <Route path='/publicaciones' element={<About nombre='Página publicaciones' />} />
        <Route path='/programa-delfin' element={<Delfin />} />
        <Route path='/galeria' element={<Galeria />} />
        <Route path='/Investigacion' element={<Investigacion />} />
        <Route path='/Qiskit2023' element={<Qiskit2023 />} />
        <Route path='/Espectros' element={<Espectros />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
      
      <Social />
      <Footer />
      <Footer_ipn/>
    </div>

  );
}

export default App;
